import React from 'react';
import ListCell from "./ListCell";
import EditIconSVG from "./icons/edit_cell_icon.svg";
import { SettingsContext } from "../SettingsContext";
import "./LinkListCell.css";
import Link from '../general/Link';

function PseudoLink(props) {
	let spanProps = {};

	["onFocus", "onClick", "onMouseUp"]
		.filter(p => props.hasOwnProperty(p))
		.forEach(p => spanProps[p] = props[p]);

	if (!props.href) {
		return <span tabIndex="0" {...spanProps} className="pseudoLink">{props.value}</span>
	}

	return <Link className="pseudoLinkAnchor" tabIndex="0" url={props.href} {...spanProps}>{props.value}</Link>;
}

class LinkListCell extends ListCell {
	static contextType  = SettingsContext;
	static defaultProps = {...ListCell.defaultProps, ...{
		urlHandler: value => value,
		valueHandler: value => value,
		noTab: true,
		asNormalLink: false,
		asText: false,
		delimiter: ', '
	}};

	constructor(props) {
		super(props);

		this.preventClickFocus = this.preventClickFocus.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}

	preventClickFocus(e) {
		e.preventDefault();
		e.stopPropagation();
	}

	handleClick(e, url = undefined) {
		if(this.props.handleClick && !this.props.returnProps) {
			this.props.handleClick(e);
			return;
		}

		e.stopPropagation();

		if(this.props.asNormalLink || url === undefined || typeof url !== "string")
			return false;

		e.preventDefault();

		let parts = url.split("?");

		if(!parts || parts.length < 2)
			return false;

		let paramParts = parts[1].split("&");

		if(!paramParts || !Array.isArray(paramParts))
			return false;

		let updateViewConfig = {};

		paramParts.forEach(part => {
			const param = part.split("=");

			if(!param || !param[0])
				return;

			updateViewConfig[param[0]] = param[1] ? param[1] : undefined;
		});

		if (this.props.handleClick && this.props.returnProps) {
			this.props.handleClick(updateViewConfig);
			return;
		}
		
		this.context.functions.updateView(updateViewConfig, e.ctrlKey || e.metaKey || e.button === 1 || !this.props.noTab);
	}


	presentValue(value) {
		const props = {
			onFocus: 	  this.preventClickFocus,
			openInNewTab: !this.props.noTab,
			asNormalLink: this.props.asNormalLink,
		};
		if (this.props.asText)
			return super.presentValue(value);

		// If the value is an array, we need to do the handling for all values.
		if(Array.isArray(value)) {
			return (
				<span className="linklistcell-multiple">
					{value.map((val, i) => {
						return (
							<React.Fragment>
								<PseudoLink
									{...props}
									href={this.props.urlHandler(val)}
									value={this.props.valueHandler(val)}
									onClick={!this.props.asNormalLink && (e => (e.button !== 1) && (this.handleClick(e, this.props.urlHandler(val))))}
									onMouseUp={e => {
                                        if(e.button === 1) {
                                            this.handleMouseUp(e);
                                        }
                                    }} />
								{
									i != value.length - 1 && (<span className="delimiter">{this.props.delimiter}</span>	)
								}
							</React.Fragment>);
					})}
				</span>
			);
		} else {
			return (
				<PseudoLink
					{...props}
					href={this.props.urlHandler(value)}
					value={this.props.valueHandler(value)}
					onClick={!this.props.asNormalLink && (e => (e.button !== 1) && (this.handleClick(e, this.props.urlHandler(value))))} />
			);
		}
	}
}

export default LinkListCell;
