import React from "react";

import TaimerComponent from "../../../TaimerComponent";
import { SettingsContext } from "../../../SettingsContext";
import OverviewBlock from "./OverviewBlock";
import colors from "../../../colors";
import OverviewBoxes from "./OverviewBoxes";
import DataHandler from "../../../general/DataHandler";
import moment from "moment";

const MODE_KEY = "my_day_activities_overview_mode";
export default class ActivitiesOverview extends TaimerComponent {
  static contextType = SettingsContext;
  constructor(props, context) {
    super(props, context, "dashboard/my_day/components/ActivitiesOverview");

    this.sliderColumns = ["type", "activity_status", "account", "project", "created", "reminder", "description", "contact"];
    this.modes = [
      {
        key: "today",
        label: this.tr("Today"),
        action: () => this._setMode("today")
      },
      {
        key: "this_week",
        label: this.tr("This Week"),
        action: () => this._setMode("this_week")
      },
      {
        key: "this_month",
        label: this.tr("This Month"),
        action: () => this._setMode("this_month")
      }
    ];

    const mode = this._getModeFromLS(MODE_KEY, "this_month");

    this.state = {
      mode,
      data: initialData,
      loading: true
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this._getData();
  }

  shouldComponentUpdate = (_, newState) => {
    return newState != this.state;
  };

  _getData = () => {
    this.setState({ loading: true }, async () => {
      const { userObject: { usersId } } = this.context
      let data = this.state.data;
      const newData = await DataHandler.get({url: `myday/activity_stats/${usersId}`});
      data.overview = newData.overview;
      data.graphData = newData.graphData;
      this.setState({ data, loading: false });
    });
  }

  _getModeFromLS = (key, defaultValue) => {
    let mode = defaultValue;
    try {
      mode = localStorage.getItem(key) || defaultValue;
    } catch (e) {}
    return mode;
  };

  _setMode = mode => {
    this.setState({ mode });
    try {
      mode = localStorage.setItem(MODE_KEY, mode);
    } catch (e) {}
  };

  _formatCurrency = (value, currency) =>
    this.context.functions.presentCurrency(value, currency);

  _renderContent = () => {
    const { data, mode } = this.state;
    const modeLabel = this.modes.find(obj => obj.key == mode).label;
    return (
      <OverviewBoxes
        topItems={[
          {
            color: colors.dark_sky_blue,
            value: data.overview[mode].new_activities.value,
            sliderData: data.overview[mode].new_activities.sliderData,
            sliderColumns: this.sliderColumns,
            sliderTitle: `${this.tr("New activities")} - ${modeLabel}`,
            title: this.tr("New activities")
          },
          {
            color: colors.carnation_pink,
            value: data.overview[mode].overdue.value,
            sliderData: data.overview[mode].overdue.sliderData,
            sliderColumns: this.sliderColumns,
            sliderTitle: `${this.tr("Overdue")} - ${modeLabel}`,
            title: this.tr("Overdue")
          }
        ]}
        bottomItems={[
          {
            color: colors.orangey_yellow,
            value: data.overview[mode].due.value,
            sliderData: data.overview[mode].due.sliderData,
            sliderColumns: this.sliderColumns,
            sliderTitle: `${this.tr("Due")} - ${modeLabel}`,
            title: this.tr("Due")
          },
          {
            color: colors.greenish_cyan,
            value: data.overview[mode].done.value,
            sliderData: data.overview[mode].done.sliderData,
            sliderColumns: this.sliderColumns,
            sliderTitle: `${this.tr("Done")} - ${modeLabel}`,
            title: this.tr("Done")
          }
        ]}
      />
    );
  };

  _getGraphData = () => {
    const { data } = this.state;
    return {
      months: data.graphData.months,
      color: colors.greenish_cyan,
      datasets: [
        {
          label: this.tr("New activities"),
          values: data.graphData.new
        },
        /*{
          label: this.tr("New activities"),
          values: data.graphData.new,
          hidden: true
        },*/
        {
          label: this.tr("Done activities"),
          values: data.graphData.done,
          hidden: true
        },
        {
          label: this.tr("Due activities"),
          values: data.graphData.due,
          hidden: true
        },
        {
          label: this.tr("Overdue activities"),
          values: data.graphData.overdue,
          hidden: true
        }
      ]
    };
  };

  render() {
    const { mode, loading } = this.state;
    return (
      <OverviewBlock
        dropdownItems={this.modes}
        overlayBottomOffset={40}
        selected={mode}
        loading={loading}
        graphData={this._getGraphData()}
        title={this.tr("Activities Overview")}
        content={this._renderContent()}
      />
    );
  }
}

const getMonths = () => {
  let months = [];
  const now = moment();
  for (let i = 5; i >= 0; i--) {
    months.push(moment(now).subtract(i, 'month').format("MM-YYYY"));
  }
  return months;
}

const initialData = {
  graphData: {
    new: [],
    overdue: [],
    done: [],
    due: [],
    total: [],
    months: getMonths()
  },
  overview: {
    today: {
      new_activities: {
        value: 0,
        sliderData: []
      },
      due: {
        value: 0,
        sliderData: []
      },
      overdue: {
        value: 0,
        sliderData: []
      },
      done: {
        value: 0,
        sliderData: []
      },
    },
    this_week: {
      new_activities: {
        value: 0,
        sliderData: []
      },
      due: {
        value: 0,
        sliderData: []
      },
      overdue: {
        value: 0,
        sliderData: []
      },
      done: {
        value: 0,
        sliderData: []
      },
    },
    this_month: {
      new_activities: {
        value: 0,
        sliderData: []
      },
      due: {
        value: 0,
        sliderData: []
      },
      overdue: {
        value: 0,
        sliderData: []
      },
      done: {
        value: 0,
        sliderData: []
      },
    }
  }
};
