import React from 'react';
import { enUS } from 'date-fns/locale';

export const defaultContext = {
    userObject: {
        usersId: 1,
        fullname: "Mikko Luostarinen",
        abbreviation: "ML",
        role: "Developer",
        companies_id: "1",
        language: 'en-GB',
        hourSymbol: 'h',
        dateFormat: "YYYY-MM-DD",
        timeFormat: "HH:MM:SS",
        datetimeFormat: "YYYY-MM-DD LT",
        color: "ffffff",
        projects: 0,
        accounts: 0,
        sidebarStyle: 0,
    },
    taimerAccount: {
        numberFormat: 'fi-FI',
        currency: 'EUR',
        symbolPosition: 'start', //'start'|'end'
        symbol: '€',
        ourCompanyLang: 'en', //company language selection temporarily defined here.
        companyPrintLang: 'en',
        origin: 'onboarding',
        attachmentMaxSize: 1,
    },
    calendar: {
        locale: enUS,
        clock: 0, // 0 = 12hr, 1 = 24hr clock
        startOfWeek: 0, // 0 sunday, 1 monday
    },
    timeTracker: {
        workhour_accuracy: 15,
        slots: 4,
        startTime: [4, 0, 0],
        endTime: [22, 0, 0],
        resourcingEnabled: true,
    },
    privileges: {
        "calendar" : {},
        "workhours": {},
        "invoices": {},
        "reports": {}
    },
    functions: {
        updateView: () => {},
        checkPrivilege: () => true,
        checkPrivilegeAny: () => true,
    },
    addons: {},
    allAddOns: {},
}

export const SettingsContext = React.createContext(defaultContext);
