import React from 'react';
import PropTypes from "prop-types";

/* material ui */
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';

/* validator */
import validator from 'validator';
import { SettingsContext } from '../SettingsContext';

export const validators = {
    empty: e => !validator.isEmpty(e, { ignore_whitespace: true }),
    numeric: e => !e.length || validator.isNumeric(e),
    email: e => !e.length || validator.isEmail(e),
    link: e => !e.length || validator.isURL(e),
    phone: e => !e.length || validator.isMobilePhone(e),
    min: (e, props) => !e.length || e >= props.inputProps.min,
    percent100: e => {
		let percent = -1;

		if (typeof e === "number") {
			percent = e;
		} else if (typeof e === "string") {
			percent = Number(e.replace(",", ".").replace(/[^0-9\-\.]/,""));
		}

    	if(isNaN(percent) || percent < 0 || percent > 100) {
    		return false;
    	}
    	return true;
    },
    vatid2: e => {
        //https://www.vero.fi/yritykset-ja-yhteisot/verot-ja-maksut/arvonlisaverotus/ulkomaankauppa/arvonlisaverotunniste/eu-maiden-arvonlisaverotunnisteet/
        //https://www.avalara.com/vatlive/en/eu-vat-rules/eu-vat-number-registration/eu-vat-number-formats.html
        e = e.replaceAll(' ', '');
        const regexp = new RegExp(/^(AT)?U[0-9]{8}$|^(BE)?[0-9]{10}$|^(BG)?[0-9]{9,10}$|^(HR)?[0-9]{11}$|^(CY)?[0-9]{8}[A-Z]{1}$|^(CZ)?[0-9]{8,10}$|^(DE)?[0-9]{9}$|^(DK)?[0-9]{8}$|^(EE)?[0-9]{9}$|^(EL)?[0-9]{9}$|^(ES)?([A-Z]{1}[0-9]{8}|[0-9]{8}[A-Z]{1})$|^(FI)?[0-9]{8}$|^(FR[0-9A-Z]{2})?[0-9]{9}$|^(XI)?(([0-9]{9}])|([0-9]{12})|((GD|HA)?[0-9]{3})$)|^(HU)?[0-9]{8}$|^(IE)?(([0-9]{1}S[0-9]{5}L)|([0-9]{7}LL))$|^(IT)?[0-9]{11}$|^(LT)?([0-9]{9}|[0-9]{12})$|^(LU)?[0-9]{8}$|^(LV)?[0-9]{11}$|^(MT)?[0-9]{8}$|^(NL)?[0-9]{9}B[0-9]{2}$|^(PL)?[0-9]{10}$|^(PT)?[0-9]{9}$|^(RO)?[0-9]{2,10}$|^(SE)?[0-9]{10}01$|^(SI)?[0-9]{8}$|^(SK)?[0-9]{10}$/, 'i');
        if (!e.length || regexp.test(e)) {
            return true;
        } 
        return false;
    },
};

class OutlinedField extends React.Component {
    static contextType = SettingsContext;
    static defaultProps = {
        currency: "EUR",
		adornmentPos: "start",
        shrinkLabel: undefined,
        className: "",
        onKeyDown: () => {},
        onKeyUp: () => {},
        maximumFractionDigits: undefined,
        autoComplete: "off",
    };

    // Note: obstructs, perhaps unwantingly, "resetting" the component's value to the original one "from the outside".
    static getDerivedStateFromProps(props, state) {
        if (props.value === state.initialValue)
            return state;

        return {...state, initialValue: props.value, value: props.value === undefined ? "" : props.value};
    }
    state = {
        "initialValue": undefined,
        "value": "",
        "valid": true,
        "focus": false,
    };
    constructor(props) {
        super(props);
        ["onKeyUp", "onChange", "onFocus", "onBlur", "isValid",].forEach(e => this[e] = this[e].bind(this));
        this.ref = props.inputRef || React.createRef();
    }

    componentDidUpdate = (oldProps) => {
        if (this.props.usePropValue && oldProps.value != this.props.value) {
            this.setValue(this.props.value);
        }
    }
    focus = () => {
        this.ref.current && this.ref.current.focus();
    }
    onKeyUp (evt) {
        const { value } = evt.target;
        this.props.callOnChangeOnKeyUp && this.props.onChange(evt);
        this.setState({value});
    }
    isValid (value, setState = true) {
        value = value !== undefined ? value : this.state.value;

        const { validation } = this.props;
        const valid = !validation || validation.find(e => !validators[e](value, this.props)) === undefined;

        if (setState && valid !== this.state.valid) {
            this.setState({ valid });
        }

        return valid;
    };
    onChange (evt) {
        const { validation, onChange, onError } = this.props;
        let { value } = evt.target;

        const numeric = this.props.validation && this.props.validation.includes("numeric");
		if(numeric && (value.indexOf(",") > -1 && value.indexOf(".") === -1)) {
            evt.target.value = evt.target.value .replace(",",".");
        }

        const valid = this.props.noOnchangeValidation ? true : this.isValid(evt.target.value);
        valid && onChange && onChange(evt);
        !valid && onError && onError(evt);
    }
    onFocus (evt) {
        this.props.onFocus && this.props.onFocus(evt);
        this.props.selectOnFocus && evt.target.select && evt.target.select();
        this.setState({focus: true});
    }
    onBlur (evt) {
        evt.fromBlur = true;
        this.onChange(evt);
        this.props.onBlur && this.props.onBlur(evt);
        this.setState({focus: false});
    }
    onKeyDown = (e) => {
        // Saving in invoice view, possibly others
        if (e.keyCode === 83 && e.ctrlKey) { 
            this.onChange(e);
        } else {
            if (e.key == 'Enter' && this.props.onChangeOnEnter) {
                this.onChange(e);
            }
            this.props.onKeyDown(e);
        }
    }
    setValue = (value) => {
        this.setState({ value }); 
    }
    render() {
        let { valid, focus, value } = this.state;
        const { error, theme, InputProps, InputLabelProps, validation, useOnChange, shrinkLabel, format, inputRef, buttonVisible, buttonProps, currencyPostfix, maximumFractionDigits, ...rest } = this.props;
        const { taimerAccount } = this.context;

        let currencyFormatter = () => {};
        if (rest.currencySymbol)
            currencyFormatter = () => `${Number(value)?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: maximumFractionDigits ? maximumFractionDigits : 2 })} ${rest.currencySymbol}`;
        else 
            currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
                    style: 'currency',
                    currency: this.props.currency,
                    maximumFractionDigits: maximumFractionDigits,
                }).format;

        let { adornment, adornmentPos } = this.props;
        let children = this.props.children ? this.props.children : undefined;
        if(children) {
            children = React.Children.toArray(children);
        }
        
        const adornmentPosName = adornmentPos === "start" ? "startAdornment" : "endAdornment";
        
        if (adornment)
            rest.InputProps = {...InputProps, [adornmentPosName]: (<InputAdornment position={adornmentPos}>{adornment}</InputAdornment>)};
        else
            rest.InputProps = InputProps;

        if (InputProps && InputProps.pswdManagerProps && InputProps.pswdManagerProps.ignore_last_pass) {
            rest.inputProps = {};
            rest.inputProps["data-lpignore"] = "true";
            rest.inputProps.name= "lpignore";
            rest.inputProps.id = "lpignore";
        }
        if (rest.type === 'date')
            rest.InputLabelProps = Object.assign(InputLabelProps || {}, { shrink: true });
        else
            rest.InputLabelProps = InputLabelProps;

        if (!rest.variant)
            rest.variant = "filled";
        if (rest.select || useOnChange)
            rest.onChange = this.onChange;
        else {
            rest.onChange = this.onKeyUp;
        }

        if(rest.paperFix) {
            rest.inputProps = {
                onFocus: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }
            };
        }

        if (this.props['data-testid']) {
            rest.inputProps = {
                ...rest.inputProps,
                'data-testid': `${this.props['data-testid']}-input`
            }
        }

        let className = this.props.className;

        if(shrinkLabel !== undefined)
            rest.InputLabelProps = Object.assign(rest.InputLabelProps || {}, { shrink: this.props.shrinkLabel });

        // If the TextField is of the select variety, pass .select class to the InputLabel to keep the
        // label from changing color on focus.
        // This is defined in TaimerTheme.js -> MuiFormLabel->root->&.select.
        if(rest.select)
            rest.InputLabelProps = Object.assign(rest.InputLabelProps || {}, { className: (rest.InputLabelProps !== undefined ? rest.InputLabelProps.className : "") + " select" });

        return (
            <TextField
                data-testid={`outlined_field_${this.props.name}`}
                {...rest}
                // onKeyDown={this.props.onKeyDown}
                className={className}
                value={format === "currency" && !focus && value !== '' ? `${currencyFormatter(value)}${currencyPostfix || ''}` : value}
                error={error || (validation && !valid)}
                inputRef={this.ref}
                variant={rest.variant}
                margin="dense"
                onFocus={this.onFocus}
                onKeyDown={this.onKeyDown}
                onKeyUp={this.props.onKeyUp}
                onBlur={this.onBlur}>
                {children && children.map((child, index) => {
                    // MenuItems', that are inside a TextField select, onClick doesn't work, this is just a stupid hack to emulate that.
                    if(!rest.select || !child.hasOwnProperty("props") || !child.props.hasOwnProperty("onClick"))
                        return child;

                    // Child has onClick prop, we need to copy that callback.
                    return React.cloneElement(child, {
                        key: child.props.hasOwnProperty("key") ? child.props.key : index,
                        /*onMouseUp: child.props.onClick,*/ //looks like package update fixed at least the clicks. With these the selection is selected twice or when dropdown opens and mouse is on top of menu item.
                        /*onTouchEnd: child.props.onClick*/
                    });
                })}
            </TextField>
        );
    }
};

OutlinedField.propTypes = {
    name: PropTypes.string.isRequired,
    validation: PropTypes.array,
    error: PropTypes.bool,
};

export default OutlinedField;
